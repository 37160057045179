/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Container from '../components/Container';
import Layout from '../components/layout';
import { createOrder } from '../state/basket';
import { setOrderId } from '../utils/order';
import { isBrowser } from '../utils';
import { PaymentSelection } from '../components/Payment';
import Spinner from '../components/Spinner';
import * as analytics from '../utils/analytics';

export default ({ pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const basketRows = useSelector(state => (state.basket.items || []).length);
  const [isProcessing, setProcessing] = useState(false);
  const dispatch = useDispatch();

  const onPayButton = async paymentOptions => {
    if (isProcessing || basketRows === 0) {
      return;
    }
    try {
      setProcessing(true);
      const order = await dispatch(createOrder(paymentOptions));
      if (order && order.redirectUrl) {
        setOrderId(order.orderId, order.transactionId);
        isBrowser && window.location.assign(order.redirectUrl);
        return;
      }
      setProcessing(false);
    } catch (error) {
      //
    }
  };

  return (
    <Layout
      title={translate('buyParcel.payment.title')}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      checkShopDisturbance={true}
      showNavigation={false}
    >
      <Container variant="parcel" sx={{ position: 'relative' }}>
        {isProcessing && (
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              bg: 'white',
              opacity: 0.8,
              zIndex: 1,
            }}
          >
            <Spinner size="medium" />
          </Box>
        )}
        <PaymentSelection onPayButton={onPayButton} />
      </Container>
    </Layout>
  );
};
